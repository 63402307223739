/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/*snack bar styles*/
.mat-mdc-snack-bar-container {
    &.snackbar-success {
        --mdc-snackbar-container-color: #107718 !important;
    }

    &.snackbar-error {
        --mdc-snackbar-container-color        : #F44336 !important;
        //--mat-mdc-snack-bar-button-color    : #fff !important;
        --mdc-snackbar-supporting-text-color  : #FFF !important;
    }

    &.snackbar-warning {
        --mdc-snackbar-container-color: #f4b906 !important;
    }
}

// color success
.theme-default .light .mat-mdc-icon-button.mat-success, .theme-default.light .mat-mdc-icon-button.mat-success {
    --mdc-icon-button-icon-color: #22c55e;
}

.theme-default .light .mat-mdc-icon-button.mat-success, .theme-default.light .mat-mdc-icon-button.mat-success {
    --mat-mdc-button-persistent-ripple-color: #22c55e;
    --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}

.mat-mdc-button:not([disabled=true]).mat-success .mat-icon, .mat-mdc-icon-button:not([disabled=true]).mat-success .mat-icon, .mat-mdc-outlined-button:not([disabled=true]).mat-success .mat-icon {
    --tw-text-opacity: 1 !important;
    color: #22c55e !important;
}

.theme-default .light .mat-icon.mat-success, .theme-default.light .mat-icon.mat-success {
    color: #22c55e;
}

//color black

.theme-default .light .mat-mdc-icon-button.mat-black, .theme-default.light .mat-mdc-icon-button.mat-black {
    --mdc-icon-button-icon-color: #000000;
}

.theme-default .light .mat-mdc-icon-button.mat-black, .theme-default.light .mat-mdc-icon-button.mat-black {
    --mat-mdc-button-persistent-ripple-color: #000000;
    --mat-mdc-button-ripple-color: rgba(220, 38, 38, 0.1);
}

.mat-mdc-button:not([disabled=true]).mat-black .mat-icon, .mat-mdc-icon-button:not([disabled=true]).mat-black .mat-icon, .mat-mdc-outlined-button:not([disabled=true]).mat-black .mat-icon {
    --tw-text-opacity: 1 !important;
    color: #000000 !important;
}

.theme-default .light .mat-icon.mat-black, .theme-default.light .mat-icon.mat-black {
    color: #000000;
}


/// panel focus
/// 
.theme-default.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]){
    background: transparent;
}


.no-padding-dialog div.mat-mdc-dialog-surface {
    padding: 0 !important;
}

fuse-alert.alert-center .fuse-alert-container .fuse-alert-content{
    width: 100%;
    justify-content: center;
    align-items: center;
}